<template>
  <div id="admin-menu-list">
    <el-menu
      :default-active="this.$route.name">
      <el-menu-item
        v-for="(menu, index) in menus"
        :key="index"
        :index="menu.endpoint"
        @click="jumpTo(menu.endpoint)">
        <i :class="menu.icon"></i>
        <span slot="title">{{ menu.name }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'admin-menu-list',
  data () {
    return {
      menus: [
        { name: '个人中心', icon: 'el-icon-myblog-gerenzhongxin', endpoint: 'AdminIndex' },
        { name: '分类管理', icon: 'el-icon-myblog-category', endpoint: 'AdminClassification' },
        { name: '文章列表', icon: 'el-icon-myblog-viewlist', endpoint: 'AdminArticleList' },
        { name: '文章编辑', icon: 'el-icon-myblog-text', endpoint: 'AdminArticleEditor' },
        { name: '媒 体 库', icon: 'el-icon-myblog-listing-content', endpoint: 'AdminMediaLibrary' },
        { name: '更新日志', icon: 'el-icon-myblog-information', endpoint: 'AdminUpdate' }
      ]
    }
  },
  methods: {
    jumpTo (endpoint) {
      this.$router.push({ name: endpoint })
    }
  }
}
</script>

<style lang="stylus" scoped>
#admin-menu-list
  margin-top: 2px
  height: 100%
  .el-menu
    min-height: calc(100vh - 62px)
</style>
