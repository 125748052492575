import axios from 'axios'

import store from '@/store'
import router from '@/router'
import api from '@/api'

const axios_instance_auth = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5000,
  isRetryRequest: false,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
})

axios_instance_auth.interceptors.request.use(config => {
  const access_token = store.getters.getAccessToken
  if (!access_token) {
    router.push('/auth')
  }
  config.auth = {
    username: access_token,
    password: ''
  }
  return config
}, error => {
  return Promise.reject(error)
})

axios_instance_auth.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        if (!error.config.isRetryRequest) {
          return axios({
            url: process.env.VUE_APP_BASE_URL + api.GET_TOKEN_URL,
            method: 'POST',
            headers: { Authorization: store.getters.getAccessToken }
          }).then(response => {
            // TODO: 判断返回内容
          }).catch(error => {
            store.commit('userLogout')
            router.push('/admin/auth/login')
          })
        }
        break
    }
  } else {
    // TODO: 网络错误？
  }
  return Promise.reject(error)
})

export default function auth_http (options) {
  return new Promise((resolve, reject) => {
    axios_instance_auth(options)
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
