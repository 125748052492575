import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from '@/store/auth'
import common from '@/store/common'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth: auth,
    common: common
  },
  plugins: [createPersistedState({ key: process.env.PERSISTEDSTATE_KEY })],
  strict: true
})

export default store
