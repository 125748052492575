import { auth } from '@/api/auth'
import { admin } from '@/api/admin'
import { blog } from '@/api/blog'

export default {
  ...auth,
  ...admin,
  ...blog,
  VUE_APP_BASE_URL: process.env.VUE_APP_BASE_URL
}
