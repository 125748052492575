<template>
  <div id="app">
    <!--博客页面-->
    <div class="blog-wrap" v-if="!isAdminWrap">
      <blog-navbar />
      <div class="blog-body">
        <div class="blog-body-wrap">
          <router-view />
        </div>
      </div>
      <blog-footer />
      <transition name="slide-fade">
        <div class="to-top" @click="scrollToTarget(0)" v-show="showScrollToTop">
          <i class="el-icon-myblog-less"></i>
        </div>
      </transition>
    </div>
    <!--管理员页面-->
    <div class="admin-wrap" v-if="isAdminWrap">
      <admin-navbar />
      <div class="admin-body">
        <el-row>
          <el-col :span="3">
            <admin-menu-list />
          </el-col>
          <el-col :span="21" class="admin-body-col-container">
            <router-view />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { scroll } from '@/mixins/scroll'
import BlogNavbar from '@/components/BlogNavbar'
import BlogFooter from '@/components/BlogFooter'
import AdminNavbar from '@/components/AdminNavbar'
import AdminMenuList from '@/components/AdminMenuList'

export default {
  name: 'App',
  components: {
    BlogNavbar,
    BlogFooter,
    AdminNavbar,
    AdminMenuList,
  },
  data () {
    return {
      showScrollToTop: false
    }
  },
  mixins: [scroll],
  mounted () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollListener, false)
  },
  methods: {
    scrollListener () {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop >= 60) {
        this.showScrollToTop = true
      } else {
        this.showScrollToTop = false
      }
    },
  },
  computed: {
    ...mapGetters([
      'isAdminWrap',
    ])
  },
  created() {
  }
}
</script>

<style lang="stylus">
*
  box-sizing: border-box

html, body
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  margin: 0
  padding: 0
  color: #303133

h1, h2, h3, h4, h5, h6, ol, p, ul
  margin: 0
  padding: 0

#app
  text-align: center;

a:link
  text-decoration: none
  color: #303133
a:active
  text-decoration: none
  color: #303133
a:visited
  text-decoration: none
  color: #303133
a:hover
  text-decoration: none
  color: #303133

.blog-body
  min-height: calc(100vh - 160px)
  width: 100%
  position: relative

.blog-body-wrap
  height: 100%
  max-width: 1000px
  min-width: 375px
  min-height: calc(100vh - 90px)
  @media (max-width: 1000px)
    min-height: calc(100vh - 60px)
  margin: 0 auto
  align-items: center
  padding: 30px 10px

.to-top
  position: fixed
  right: 10px
  bottom: 35px
  padding: 10px
  z-index: 1050
  cursor: pointer
  line-height: 0
  display: flex
  flex-direction: row
  align-items: flex-start
  background-color: #f8f8f8

.slide-fade-enter-active
  transition: all .3s ease
.slide-fade-leave-active
  transition: all .3s ease
.slide-fade-leave-to
  transform: translateY(35px)
  opacity: 0

.admin-wrap
  height: 100%
  min-height: 100vh
  min-width: 1366px
  .admin-body
    height: 100%
    .admin-body-col-container
      height: calc(100vh - 62px)
      overflow-y: auto
      .admin-contents
        text-align: left
        .admin-contents-title
          font-size: 14pt
          font-weight: 600
          width: 100%
          height: 60px
          line-height: 60px
          padding: 0 20px
        .admin-contents-wrap
          margin: 20px
</style>
