<template>
  <div id="admin-navbar">
    MrLin's Blog 管理
  </div>
</template>

<script>
export default {
  name: 'admin-navbar'
}
</script>

<style lang="stylus" scoped>
#admin-navbar
  width: 100%
  height: 60px
  line-height: 60px
  text-align: left
  padding-left: 20px
  font-size: 16pt
  box-shadow: 0 0 5px 0 rgba(38, 42, 48, .1)
</style>
