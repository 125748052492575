import axios from 'axios'

import api from '@/api'

const state = {
  access_token: null
}

const getters = {
  isAuthenticated (state) {
    return (state.access_token !== null)
  },
  getAccessToken (state) {
    return state.access_token
  }
}

const actions = {
  userLogin ({ state, commit }, data) {
    /**
     * @description 提供用户登录方法，登录成功之后把 token 返回。
     * @param state
     * @param commit
     * @param data 必须带有 {username: 'xxx', password: 'xxx'} 参数。
     */
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: api.VUE_APP_BASE_URL + api.GET_TOKEN_URL,
        auth: {
          username: +data.username,
          password: data.password
        }
      }).then(function (response) {
        return resolve(response.data)
      }).catch(function (error) {
        return reject(error.response)
      })
    })
  }
}

export const mutations = {
  setToken (state, data) {
    state.access_token = data || null
  },
  userLogout (state) {
    // this.commit('setUserInfo', {})
    state.access_token = null
  }
}

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
