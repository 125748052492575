import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '*',
    redirect: '/pageNotFound'
  },
  {
    path: '/home',
    name: 'HomeIndex',
    component: resolve => require(['@/views/blog/home/index'], resolve)
  },
  {
    path: '/categories',
    name: 'CategoriesIndex',
    component: resolve => require(['@/views/blog/categories/index'], resolve)
  },
  {
    path: '/tags',
    name: 'TagsIndex',
    component: resolve => require(['@/views/blog/tags/index'], resolve)
  },
  {
    path: '/article/:articleId',
    name: 'Article',
    component: resolve => require(['@/views/blog/article/index'], resolve)
  },
  {
    path: '/category/articleList/:categoryId',
    name: 'CategoryArticleList',
    component: resolve => require(['@/views/blog/categories/articleList'], resolve)
  },
  {
    path: '/tag/articleList/:tagId',
    name: 'TagArticleList',
    component: resolve => require(['@/views/blog/tags/articleList'], resolve)
  },
  {
    path: '/update',
    name: 'Update',
    component: resolve => require(['@/views/blog/update'], resolve)
  },
  {
    path: '/pageNotFound',
    name: 'PageNotFound',
    component: resolve => require(['@/views/404/index'], resolve)
  },
  // 管理员页面
  {
    path: '/admin/auth/login',
    name: 'AdminLogin',
    component: resolve => require(['@/views/admin/auth/login'], resolve)
  },
  {
    path: '/admin/index',
    name: 'AdminIndex',
    component: resolve => require(['@/views/admin/home/index'], resolve),
    meta: { requireAuth: true }
  },
  {
    path: '/admin/classification',
    name: 'AdminClassification',
    component: resolve => require(['@/views/admin/classification'], resolve),
    meta: { requireAuth: true }
  },
  {
    path: '/admin/article/list',
    name: 'AdminArticleList',
    component: resolve => require(['@/views/admin/article/list'], resolve),
    meta: { requireAuth: true }
  },
  {
    path: '/admin/article/editor',
    name: 'AdminArticleEditor',
    component: resolve => require(['@/views/admin/article/editor'], resolve),
    meta: { requireAuth: true }
  },
  {
    path: '/admin/media',
    name: 'AdminMediaLibrary',
    component: resolve => require(['@/views/admin/media'], resolve),
    meta: { requireAuth: true }
  },
  {
    path: '/admin/update',
    name: 'AdminUpdate',
    component: resolve => require(['@/views/admin/update'], resolve),
    meta: { requireAuth: true }
  }
]

const router = new Router({
  mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.fullPath.indexOf('/admin') !== -1) {
    store.commit('IS_ADMIN_WRAP', true)
  } else {
    store.commit('IS_ADMIN_WRAP', false)
  }
  if (to.meta.requireAuth) {
    if (store.getters.isAuthenticated) {
      next()
    } else {
      router.push('/admin/auth/login')
    }
  } else if (!to.meta.requireAuth) {
    next()
  }
})

export default router
