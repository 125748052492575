<template>
  <div id="blog-footer">
    <div class="footer-wrap">
      <div class="blog-runtime">{{ runningTime }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'blog-footer',
  data () {
    return {
      runningTime: ' 天 小时 分 秒'
    }
  },
  methods: {
    running () {
      const startTime = new Date('2019/03/20 10:00:00')
      setInterval(() => {
        const time = new Date() - startTime
        const day = parseInt(time / 1000 / 60 / 60 / 24, 10)
        let hour = parseInt(time / 1000 / 60 / 60 % 24, 10)
        if (hour < 10) hour = '0' + hour
        let minute = parseInt(time / 1000 / 60 % 60, 10)
        if (minute < 10) minute = '0' + minute
        let second = parseInt(time / 1000 % 60, 10)
        if (second < 10) second = '0' + second
        this.runningTime = `${day} 天 ${hour} 小时 ${minute} 分 ${second} 秒`
      }, 1000)
    }
  },
  created () {
    this.running()
  }
}
</script>

<style lang="stylus" scoped>
#blog-footer
  width: 100%
  height: 50px
  background: #5a5a5a
  bottom: 0
  position: relative

.footer-wrap
  position: relative
  height: 100%
  max-width: 1000px
  margin: 0 auto
  align-items: center
  padding-top: 10px

.blog-runtime
  color: #e0e0e0
  font-size: 12pt
  height: 30px
  line-height: 30px
</style>
