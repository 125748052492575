export const admin = {
  ADMIN_INFO_URL: '/v1/admin/info',
  ADMIN_COS_AUTH_URL: '/v1/admin/cosAuth',
  ADMIN_MEDIA_URL: '/v1/admin/media',
  ADMIN_ARTICLE_URL: '/v1/admin/article',
  ADMIN_ARTICLES_URL: '/v1/admin/articles',
  ADMIN_CATEGORIES_URL: '/v1/admin/categories',
  ADMIN_TAGS_URL: '/v1/admin/tags',
  ADMIN_UPDATE_LOG_URL: '/v1/admin/updateLog'
}
