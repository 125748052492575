const state = {
  isAdminWrap: false
}

const getters = {
  isAdminWrap (state) {
    return state.isAdminWrap
  }
}

const mutations = {
  IS_ADMIN_WRAP (state, data) {
    state.isAdminWrap = data
  }
}

const actions = {
  setIsAdminWrap (store, isAdminWrap) {
    store.state.isAdminWrap = isAdminWrap
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
