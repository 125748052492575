import axios from 'axios'
import router from '@/router'

const axios_instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
})

axios_instance.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})

axios_instance.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.reject(error)
})

export default function http (options) {
  return new Promise((resolve, reject) => {
    axios_instance(options)
      .then(response => {
        if (response.data.code === 404) {
          router.push({ name: 'PageNotFound' })
        } else {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
