import Vue from 'vue'
import App from './App'
import router from './router'
import ELEMENT from 'element-ui'
import '@/assets/custom_icon/iconfont.css'
import Highlight from '@/utils/blogHighlight'
import store from '@/store'
import VueLazyload from 'vue-lazyload'
import mavonEditor from 'mavon-editor'

// axios 挂载
import http from '@/utils/http'
Vue.prototype.$http = http

// 带有拦截器的 axios 挂载
import auth_http from '@/utils/auth_http'
Vue.prototype.$auth_http = auth_http

// api 挂载
import api from '@/api'
Vue.prototype.$api = api

Vue.use(ELEMENT)
Vue.use(Highlight)
Vue.use(VueLazyload)
Vue.use(mavonEditor)
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: h => h(App)
})