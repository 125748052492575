<template>
  <div>
    <div class="pc-nav-bg hidden-sm-and-down">
      <div class="pc-nav">
        <div
          class="pc-nav-blog-name"
          @click="jumpTo('HomeIndex')">
          <div class="line"></div>
          <p>MrLin's Blog</p>
          <div class="line"></div>
        </div>
        <div class="pc-nav-blog-items">
          <div class="pc-nav-blog-item"
            v-for="(tab, index) in tabs"
            :key="index"
            @click="jumpTo(tab.endpoint)">
            <i :class="tab.icon"></i>{{ tab.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-nav hidden-md-and-up">
      <div>
        <p class="mobile-nav-blog-name">MrLin's Blog</p>
      </div>
      <div>
        <i :class="{'el-icon-arrow-down': mobileNavToggleOpen, 'el-icon-close': mobileNavToggleClose}"
           @click="mobileNavToggle"></i>
      </div>
    </div>

    <div class="hidden-md-and-up">
      <el-collapse-transition>
        <div class="mobile-nav-list" v-show="mobile_nav_list_show">
          <div class="mobile-nav-list-item"
             v-for="(tab, index) in tabs"
             :key="index"
             @click="jumpTo(tab.endpoint)">
            <i :class="tab.icon"></i>
            <span>{{ tab.name }}</span>
          </div>
        </div>
      </el-collapse-transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'blog-navbar',
  data () {
    return {
      mobile_nav_list_show: false,
      mobileNavToggleOpen: true,
      mobileNavToggleClose: false,
      tabs: [
        { name: '主页', icon: 'el-icon-myblog-warehouse-delivery', endpoint: 'HomeIndex' },
        { name: '分类', icon: 'el-icon-myblog-folder', endpoint: 'CategoriesIndex' },
        { name: '标签', icon: 'el-icon-myblog-discount', endpoint: 'TagsIndex' },
        { name: '日志', icon: 'el-icon-myblog-information', endpoint: 'Update' }
      ]
    }
  },
  methods: {
    mobileNavToggle () {
      this.mobile_nav_list_show = !this.mobile_nav_list_show
      this.mobileNavToggleOpen = !this.mobileNavToggleOpen
      this.mobileNavToggleClose = !this.mobileNavToggleClose
    },
    jumpTo (endpoint) {
      this.$router.push({ name: endpoint })
    }
  }
}
</script>

<style lang="stylus" scoped>
.mobile-nav
  height: 60px
  margin: 0
  border-bottom: #f1f1f1 solid 1px
  background-color: #f8f8f8
  padding: 0 20px
  line-height: 60px
  display: flex
  justify-content: space-between
  min-width: 375px

.mobile-nav-blog-name
  font-weight: 500
  font-size: 16pt

.mobile-nav-list
  text-align: left
  background-color: #fafafa
  padding: 5px 20px

.mobile-nav-list-item
  margin: 15px 0
  font-size: 10pt
  color: #414244

.mobile-nav-list-item span
  margin-left: 5px

.pc-nav-bg
  background-color: #f8f8f8
  width: 100%

.pc-nav
  position: relative
  display: flex
  justify-content: space-between
  height: 90px
  align-items: center
  max-width: 1000px
  margin: 0 auto
  padding: 0 10px

.pc-nav-blog-name
  height: 100%
  text-align: center
  padding-top: 6px
  &:hover
    cursor: pointer
    .line
      width: 0

.pc-nav-blog-name > p
  font-size: 16pt
  font-weight: 500
  line-height: 40px
  margin: -1px 0 -10px 0

.line
  background-color: #303133
  height: 2px
  transition: width .3s
  width 70px
  display: inline-block

.pc-nav-blog-item
  display: inline-block
  box-sizing: border-box
  line-height: 90px
  height: 90px
  font-size: 10pt
  padding: 0 15px
  color: #999
  transition: background .5s, color .5s, padding .5s
  &:hover
    cursor: pointer
    background: #eee
    color: #303133
    padding: 0 30px
  i
    margin-right: 5px
</style>
